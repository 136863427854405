body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


table {
  margin-top: 20px;
}

.pagination {
  margin-bottom: 50px;
}

.page-num {
  margin-left: 20px;
  margin-top: 5px;
}

.page-select {
  margin-left: 20px;
  height: 30px;
}

.section-header {
  margin-top: 20px;
}

.pct-class {
  margin-left: 20px;
  margin-bottom: 20px;
  padding-top: 10px;
}

.performance-stats-card {
  margin-top: 25px;
}
.text-danger {
  color: red;
}