* {
  font-family: 'Gellix';
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
}

a:hover {
  text-decoration: none;
  color: #fff;
  /*color: inherit;*/
}

.minor-text a {
  text-decoration: underline;
  color: inherit;
}

.minor-text a:hover {
  text-decoration: underline;
  color: #fff;
}

h1 {
  margin-top: 1em;
  color: #fff;
  font-weight: 600;
  font-size: 3em;
  line-height: 58px;
}

body {
  background-color: #2D1446;
  color: #fff;
}

.header {
  color: #2D1446;
}

.container {
  padding-left: 0px;
}

.subheading {
  /*width: 627px;*/
  width: 60%;
  margin-top: 21px;
}

.minor-text {
  color: rgba(255, 255, 255, 0.7);
}

.badge-purple {
  background: #8856a7 !important;
  color: white;
}

.badge-orange {
  background: #d95f0e !important;
  color: white;
}

.text-purple {
  color: #DDA0DD !important;
  font-weight: bold;
}

.text-orange {
  color: #d95f0e !important;
  font-weight: bold;
}

.link {
  color: #782396;
}

.button-m {
  display: -ms-grid;
  display: grid;
  padding: 13px 28px;
  margin: 1em;
  margin-left: 0em;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 0.67em;
  grid-row-gap: 16px;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-radius: 125px;
  background-color: #782396;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.button-m:hover {
  background-color: #a028aa;
}

.button-m.is-ghost {
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  background-color: transparent;
  color: #fff;
}

.button-m.is-secondary {
  background-color: rgba(255, 255, 255, 0.1);
}

.button-m.is-secondary:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.button-m.with-border {
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.3);
}

.button-m.is-white {
  border-radius: 100px;
  background-color: #fff;
  color: #782396;
}

.button-m.is-white.with-border {
  border-radius: 100px;
  background-color: #fff;
  color: #782396;
  border-style: solid;
  border-width: 1px;
  border-color: #782396;
  margin: 0px 10px 63px 10px;
}

.text-m {
  font-size: 1em;
}

.text-s {
  font-size: 0.88em;
}

.text-m.is-drop-placeholder {
  opacity: 0.48;
}

.text-m.is-label {
  padding-top: 0.8em;
  font-weight: 600;
}

.chart-container {
  display: flex;
}

.chart-info-container {
  display: flex;
}

.embedding-page-container {
  margin-top: 2em;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  width: 100%;
  /*height: 900px;*/
  display: inline-block;
}

.info-notice {
  padding: 19px;
  margin: 25px;
  background-color: rgba(0, 220, 200, 0.2);
  border-radius: 5px;
  display: flex;
  font-size: 14px;
  /*width: 557px;*/
  width: 66%;
  height: auto;
  /*height: 80px;*/
}

.info-icon {
  position: absolute;
}

.csv-uploader-container {
  margin-top: 87px;
  background-color: #fff;
  border-radius: 10px;
  color: #000;
  width: 100%;
  display: flex;
}

.csv-drop-header {
  font-weight: 600;
  margin-top: 36px;
  margin-bottom: 7px;
  font-size: 16px;
}

.csv-explore-header {
  margin-top: 56px;
  margin-bottom: 43px;
  font-weight: 600;
}

.csv-drop-container { 
  width: 100%;
  text-align: center;
}

.csv-explore-button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.csv-uploader {
  display: inline-block;
  margin: 0 auto;
  margin-top: 96px;
/*  left: auto;
  right: auto;*/
  width: 435px;
  height: 360px;
  color: rgba(26, 26, 26, 0.4);
}

.spinner-container {
  margin-bottom: 63px;
}

.text-display-container {
  background-color: #1E0A32;
  border-radius: 4px;
  /*width: 303px;*/
  width: 28%;
  float: right;
  margin: 15px;
  padding: 20px;
  color: white;
  overflow-y: scroll;
}

h4, h5 {
  font-weight: 600;
}

.h5 {
  font-weight: 600;
  color: white;
}

.text-display-header {
  display: flex;
}

.text-display-content {
  font-size: 14px;
}

.header {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 2000;
  width: 100%;
  height: 6.67em;
  padding-right: 3.2em;
  padding-left: 3.2em;
  /*background-color: transparent;*/
}

.header-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 100em;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.w-nav {
  position: relative;
  z-index: 1000;
}
.w-nav:before,
.w-nav:after {
  content: " ";
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.w-nav:after {
  clear: both;
}

.header-menu {
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-menu-content {
  display: -ms-grid;
  display: grid;
  height: 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 3.03em;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.w-nav-menu {
  position: relative;
  float: right;
}

.nav-link {
  font-weight: 600;
  font-size: 1em;
  padding: 0em;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.spacer {
    flex: 1 1 auto;
}

.footer {
  position: relative;
  z-index: 10;
  overflow: hidden;
  width: 100%;
  padding-top: 5em;
}

.footer-content {
  display: -ms-grid;
  display: grid;
  width: 100%;
  max-width: 87.27em;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.52em;
  grid-row-gap: 0px;
  -ms-grid-columns: 6fr 8fr;
  grid-template-columns: 6fr 8fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.footer-up {
  position: relative;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  margin-left: 1.21em;
  margin-right: 1.21em;
}

.footer-up-bg {
  position: absolute;
  left: auto;
  top: 0%;
  right: auto;
  bottom: 0%;
  z-index: 0;
  width: 1000vw;
  background-position: 50% 0%;
  background-size: contain;
  background-repeat: repeat-x;
  opacity: 0;
}

.footer-left {
  display: -ms-grid;
  display: grid;
  width: 17.5em;
  padding-top: 6em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-row-gap: 0.68em;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.footer-links-box {
  display: -ms-grid;
  display: grid;
  margin-top: 2.5em;
  margin-right: 1.21em;
  margin-left: 1.21em;
  padding-top: 2.5em;
  padding-bottom: 2.5em;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
  grid-auto-columns: 1fr;
  grid-column-gap: 1.52em;
  grid-row-gap: 16px;
  -ms-grid-columns: 3fr 3fr 2fr;
  grid-template-columns: 3fr 3fr 2fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

.subfooter {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 1.21em;
  margin-left: 1.21em;
  padding-top: 3.03em;
  padding-bottom: 3.03em;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  /*color: rgba(0, 0, 0, 0.5);*/
}

.footer-links-column {
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-columns: 1fr;
  grid-row-gap: 2em;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.footer-links {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  color: rgba(255, 255, 255, 0.7);
}

.footer-link {
  padding-bottom: 0.71em;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.footer-link:hover {
  color: rgba(255, 255, 255, 1);;
}

.footer-demo-link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 7.58em;
  padding-top: 0.8em;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  text-decoration: none;
}

.footer-demo {
  background-image: url('./arrow-top-right-1.svg');
  background-position: 100% 80%;
  background-size: auto;
  background-repeat: no-repeat;
}

.subfooter-links {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  grid-column-gap: 1.52em;
  -ms-grid-columns: auto;
  grid-template-columns: auto;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.subfooter-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.subfooter-link:hover {
  color: white;
}

@media (min-width: 1200px)
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
}

@font-face {
  font-family: 'Space Grotesk';
  src: local('Space Grotesk'), url(./fonts/Space Grotesk/SpaceGrotesk-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Space Grotesk';
  font-weight: 900;
  src: local('Space Grotesk'), url(./fonts/Space Grotesk/SpaceGrotesk-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gellix';
  src: local('Gellix'), url(./fonts/Gellix/Gellix-Regular.otf) format('opentype');
}

@font-face {
  font-family: 'Gellix';
  font-weight: 600;
  src: local('Gellix'), url(./fonts/Gellix/Gellix-SemiBold.otf) format('opentype');
}

@font-face {
  font-family: 'Gellix';
  font-weight: 900;
  src: local('Gellix'), url(./fonts/Gellix/Gellix-Bold.otf) format('opentype');
}